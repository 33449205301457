import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { RoutePathEnum } from '../../core/router.enum';
import { AuthService } from './../../services/auth/auth.service';
import { EMPTY, catchError, finalize } from 'rxjs';
import { VerificationPreference } from '@src/app/services/auth/auth.service.types';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss'],
})
export class OtpVerifyComponent implements OnInit {
  otpCodeLength = 6;
  invalidOtp = false;
  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: this.otpCodeLength,
  };
  reservationUser$ = this.authService.user$;

  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput?: NgOtpInputComponent;
  initiateOtpLoading = false;
  form: FormGroup;
  verifyOtpLoading = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  ngOnInit(): void {
    const user = this.reservationUser$.value;
    if (!user) return;
    this.initiateOtpLoading = true;
    this.authService
      .sendOtp(user.phoneNumber)
      .pipe(
        finalize(() => {
          this.initiateOtpLoading = false;
        })
      )
      .subscribe();
  }

  get otpControl(): FormControl {
    return this.form.get('otp') as FormControl;
  }

  onVerifyOtp(): void {
    this.verifyOtpLoading = true;
    this.authService
      .verifyOtp(this.form.value.otp)
      .pipe(finalize(() => (this.verifyOtpLoading = false)))
      .subscribe((response) => {
        this.invalidOtp = response.statusCode !== 200;
        this.invalidOtp
          ? this.ngOtpInput?.setValue('')
          : this.authService.isLicenseVerified()
          ? this.router.navigate([
              `${RoutePathEnum.EXPRESS_CHECK_IN}/${RoutePathEnum.SUBMIT}`,
            ])
          : this.router.navigate([RoutePathEnum.UPLOAD_PHOTOS]);
      });
  }

  onResendOtp(): void {}
}
