import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button
      class="btn btn-primary"
      ngClass="{{ class }}"
      (click)="handleClick.emit()"
      [disabled]="isDisabled"
    >
      {{ text }}
      <img
        *ngIf="hasNext"
        class="icon-img"
        src="assets/icons/arrow-right.svg"
        alt=""
      />
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="isLoading"
      ></span>
    </button>
  `,
  styles: [
    `
      button {
        text-transform: uppercase;
        font-weight: 300 !important;
        color: white !important;
      }
    `,
  ],
})
export class ButtonComponent {
  @Output() handleClick = new EventEmitter<void>();
  @Input() isDisabled = false;
  @Input() hasNext = false;
  @Input() text = '';
  @Input() isLoading = false;
  @Input() class = '';
}
