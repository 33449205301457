import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  dealerLogoUrl?:string
  constructor(private authService: AuthService) {}
  ngOnInit(): void {
    this.authService.dealerViewModel$.subscribe((viewModel) => {
      this.dealerLogoUrl = viewModel?.dealerLogo;
    });
  }

}
