<div class="container body d-flex flex-column gap-4">
  <div class="header text-center">
    You have made rental booking for pickup on
    <strong>{{ startDate | date: 'shortDate' }}</strong>. Kindly confirm all details are
    <strong>correct and complete.</strong>
  </div>
  <div class="upload-container d-flex">
    <div class="header">
      <img src="assets/icons/camera-solid.svg" alt="" />
      Upload Photos
      <div class="hr"></div>
    </div>
    <div class="file-upload-container">
      <app-upload
        (handleFileUpload)="onFileUpload($event)"
        (handleFileRemove)="onFileRemove($event)"
        fileType="LicenseFront"
      ></app-upload>
      <app-upload
        (handleFileUpload)="onFileUpload($event)"
        (handleFileRemove)="onFileRemove($event)"
        fileType="LicenseBack"
      ></app-upload>
      <app-upload
        (handleFileUpload)="onFileUpload($event)"
        (handleFileRemove)="onFileRemove($event)"
        fileType="Insurance"
      ></app-upload>
    </div>
  </div>
  <div class="footer">
    <app-button
      text="Verify"
      [hasNext]="true"
      [isDisabled]="
        !fileTypeToFile || (fileTypeToFile | keyvalue).length !== 3 || isLoading
      "
      (handleClick)="onVerify()"
      [isLoading]="isLoading"
    ></app-button>
  </div>
</div>
