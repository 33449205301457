export enum RoutePathEnum {
  OTP_VERIFY = '/otp-verify',
  UPLOAD_PHOTOS = '/upload-photos',
  CHECK_CONTRACT = '/check-contract',
  EXPRESS_CHECK_IN = '/express-check-in',
  PERSONAL_INFO = 'personal-info',
  LICENSE_AND_INSURANCE = 'license-and-insurance',
  SUBMIT = 'submit',
  COMPLETE = 'complete',
}
