<ng-container *ngIf="initiateOtpLoading; else otpVerifyPage">
  <h1>Loading ...</h1>
</ng-container>
<ng-template #otpVerifyPage>
  <ng-container *ngIf="reservationUser$ | async as user">
    <div class="body container otp-verify-page">
      <div class="body">
        <div class="info">
          <div class="header">
            Hi, {{ user?.firstName }} {{ user?.lastName }}!
            <br />
            Let's start by verifying it's you.
          </div>
          <div class="body">
            Kindly enter the 6-digit one-time verification code sent to your
            phone number *** ** *{{ user.phoneNumber.slice(-2) }}
          </div>
        </div>
        <form class="d-flex gap-1 flex-column" formGroup="form">
          <ng-otp-input
            [config]="otpConfig"
            [formCtrl]="otpControl"
          ></ng-otp-input>
          <div *ngIf="invalidOtp" class="form-error">
            The 6-digit code you entered is incorrect.
          </div>
        </form>
        <div class="opt-action-text">Did't receive code?</div>
        <app-button text="Resend" (handleClick)="onResendOtp()"></app-button>
      </div>
      <div class="footer">
        <app-button
          text="Next"
          [hasNext]="true"
          [isDisabled]="form.invalid"
          (handleClick)="onVerifyOtp()"
          [isLoading]="verifyOtpLoading"
        ></app-button>
      </div>
    </div>
  </ng-container>
</ng-template>
