import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../../services/auth/auth.service';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, switchMap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.url.includes('.s3.us')) return next.handle(req);
    const accessToken = this.auth.accessToken;
    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return next.handle(req);
    }

    if (this.auth.token?.refreshToken) {
      return this.auth.refreshToken(this.auth.token).pipe(
        catchError((err) => {
          console.log('Can not get access token using refresh token', err);
          this.auth.deleteToken();
          return EMPTY;
        }),
        switchMap((res) => {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${res.data.accessToken}`,
            },
          });

          return next.handle(req);
        })
      );
    }
    return next.handle(req);
  }
}
