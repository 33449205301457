<div class="file-upload">
  <input
    type="file"
    [id]="fileType"
    accept="image/jpg, image/png"
    hidden
    (change)="onImageUpload($event)"
  />
  <div
    class="file-upload-btn"
    [ngClass]="{ 'file-upload-error': !validImageSize }"
  >
    <button class="btn close-btn" *ngIf="imageUrl" (click)="onRemoveImage()">
      <img class="icon-img" src="assets/icons/close-shadow.svg" alt="" />
    </button>
    <label class="upload-img-label" [for]="fileType" *ngIf="!imageUrl">
      <img class="icon-img" src="assets/icons/camera.svg" alt="" />
      {{ label }}
    </label>
    <div class="uploaded-img-container h-100 w-100">
      <img class="uploaded-img" [src]="imageUrl" alt="" *ngIf="imageUrl" />
    </div>
    <label [for]="fileType" class="invalid-img" *ngIf="!validImageSize">
      <img class="icon-img-lg" src="assets/icons/unverified.svg" alt="" />
      <div class="footer">Image unusable. Choose a different photo.</div>
    </label>
  </div>
</div>
