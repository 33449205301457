import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import {
  EMPTY,
  Observable,
  catchError,
  finalize,
  forkJoin,
  lastValueFrom,
} from 'rxjs';
import { ExtensionType, FileType } from '@services/app.service.types';
import { AuthService } from '@src/app/services/auth/auth.service';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.scss'],
})
export class UploadPhotosComponent implements OnInit {
  fileTypeToFile?: Record<FileType, File>;
  isLoading = false;
  startDate =
    this.authService.startDateTime && new Date(this.authService.startDateTime);

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onFileUpload(file: Record<FileType, File>): void {
    const observables: Observable<any>[] = [];

    Object.keys(file).forEach((key) => {
      const fileType = key as FileType;
      if (fileType in file) {
        const fileObj = file[fileType];
        const fileName = fileObj.name;
        const fileExtension = fileName.split('.').pop() as ExtensionType;

        observables.push(
          this.appService.getAndSetPreAuthUrl(fileType, fileExtension)
        );
      }
    });

    if (observables.length > 0) {
      forkJoin(observables).subscribe({
        next: () => {
          this.fileTypeToFile = {
            ...(this.fileTypeToFile || {}),
            ...file,
          };
        },
        error: (err) => {
          console.error('Error setting pre-signed URLs', err);
        },
      });
    }
  }

  onFileRemove(fileType: FileType): void {
    delete this.fileTypeToFile?.[fileType];
  }

  onVerify(): void {
    if (!this.fileTypeToFile) return;
    this.isLoading = true;
    this.appService
      .uploadFilesToGCS(this.fileTypeToFile)
      .pipe(
        finalize(() => (this.isLoading = false)),
        catchError((err) => EMPTY)
      )
      .subscribe((res) => {
        this.router.navigate(['/express-check-in']);
      });
  }
}
