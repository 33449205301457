import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { UploadComponent } from './upload/upload.component';
import { ButtonComponent } from './button/button.component';
import { CommonModule } from '@angular/common';
const components = [HeaderComponent, UploadComponent, ButtonComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
})
export class ComponentsModule {}
