<div class="image-overlay-container">
  <img
    class="overlay-image"
    src="assets/images/connexion-logo.svg"
    alt="Overlay Image"
  />
  <ng-container *ngIf="dealerLogoUrl">
    <img
      class="dealer-logo"
      [src]="dealerLogoUrl"
      alt="Dealer Logo"
    />
  </ng-container>
</div>
