import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FullPageStatusComponent } from './pages/full-page-status/full-page-status.component';
import { UploadPhotosComponent } from './pages/upload-photos/upload-photos.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ContractComponent } from './pages/contract/contract.component';
import { ComponentsModule } from './components/components.module';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    FullPageStatusComponent,
    UploadPhotosComponent,
    OtpVerifyComponent,
    ContractComponent,
  ],
  imports: [
    BrowserModule,
    NgOtpInputModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ComponentsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
