import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateWithoutValidToken } from './core/auth.guard';
import { ContractComponent } from './pages/contract/contract.component';
import { OtpVerifyComponent } from './pages/otp-verify/otp-verify.component';
import { UploadPhotosComponent } from './pages/upload-photos/upload-photos.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
  },
  {
    path: 'otp-verify',
    component: OtpVerifyComponent,
  },
  {
    path: 'upload-photos',
    component: UploadPhotosComponent,
    canActivate: [canActivateWithoutValidToken],
  },
  {
    path: 'check-contract',
    component: ContractComponent,
    canActivate: [canActivateWithoutValidToken],
  },
  {
    path: 'express-check-in',
    canActivate: [canActivateWithoutValidToken],

    loadChildren: () =>
      import('./pages/express-check-in/express-check-in.module').then(
        (m) => m.ExpressCheckInModule
      ),
  },
  {
    path: '*',
    redirectTo: 'otp-verify',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
})
export class AppRoutingModule {}
