<div class="spinner">
  <div class="spinner-body">
    <ng-container *ngIf="status === 'loading'">
      <div class="spinner-border" role="status"></div>
      Verifying your identify with Stripe <br />
      Please wait...
    </ng-container>
    <ng-container *ngIf="status === 'error'">
      <img class="img" src="assets/icons/warning-triangle.svg" alt="" />
      We have not been able to verify your details at this time. Only verified
      customers are approved for loading vehicles. Please contact the
      dealerships as soon as possible to confirm your details.
    </ng-container>
  </div>
</div>
