import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './../services/auth/auth.service';

@Injectable({ providedIn: 'root' })
class AuthGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return !!this.authService.accessToken;
  }
}

export const canActivateWithoutValidToken: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  return inject(AuthGuard).canActivate(route);
};
