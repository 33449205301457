import { lastValueFrom } from 'rxjs';
import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { RoutePathEnum } from '@core/router.enum';
import { VerificationPreference } from './services/auth/auth.service.types';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'express-check-in-website';
  loading = true;
  reservationError?: string;
  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    try {
      const reservationId = this.getReservationId();
      await this.loadReservationAndDealerDetails(reservationId);
      await this.authService.loadTokenFromStorage();
      this.authService.token
        ? this.authService.isLicenseVerified()
          ? this.router.navigate([
              `${RoutePathEnum.EXPRESS_CHECK_IN}/${RoutePathEnum.SUBMIT}`,
            ])
          : this.router.navigate([RoutePathEnum.UPLOAD_PHOTOS])
        : this.router.navigate([RoutePathEnum.OTP_VERIFY]);
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  getReservationId(): string {
    const reservationId = window.location.href.split('?reservationId=')[1];
    return reservationId?.replaceAll('%23', '#');
  }

  private async loadReservationAndDealerDetails(
    reservationId?: string
  ): Promise<void> {
    if (!reservationId) return;
    try {
      await lastValueFrom(
        this.authService.fetchReservationDetails(reservationId)
      );
      if (this.authService.vehicle?.dealerId) {
        this.authService.getDealerById(this.authService.vehicle?.dealerId).subscribe();
      }
      this.reservationError = undefined;
    } catch (err: any) {
      this.reservationError =
        err.status === 404
          ? 'Reservation not found.'
          : 'Something went wrong. Please try again later.';
      throw err;
    }
  }
}
