import { Component, Input } from '@angular/core';

export type Status = 'loading' | 'success' | 'error';

@Component({
  selector: 'app-full-page-status',
  templateUrl: './full-page-status.component.html',
  styleUrls: ['./full-page-status.component.scss'],
})
export class FullPageStatusComponent {
  @Input() status: Status = 'loading';
}
