import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { FileType } from '@services/app.service.types';

const fileTypeToLabel: Record<FileType, string> = {
  LicenseFront: 'License Front',
  LicenseBack: 'License Back',
  Insurance: 'Insurance Card',
};
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  imageUrl?: string | ArrayBuffer | null = null;
  validImageSize = true;
  @Input() fileType: FileType = 'LicenseFront';
  @Output() handleFileUpload = new EventEmitter<Record<FileType, File>>();
  @Output() handleFileRemove = new EventEmitter<FileType>();

  get label(): string {
    return fileTypeToLabel[this.fileType];
  }

  async onImageUpload(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file?.type.startsWith('image/')) {
      this.validImageSize = await this.isValidImageSize(file);
      this.onFileSuccess(file);
      if (!this.validImageSize) return;
      this.onFileUploadSuccess(file);
    } else {
      alert('Please upload a valid image file.');
    }
  }

  onRemoveImage(): void {
    this.imageUrl = null;
    this.handleFileRemove.emit(this.fileType);
  }

  private onFileSuccess(file: File): void {
    const reader = new FileReader();
    // istanbul ignore next
    reader.onload = (e) => (this.imageUrl = e.target?.result);
    reader.readAsDataURL(file);
  }

  private onFileUploadSuccess(file: File): void {
    const fileObject: Record<FileType, File> = {
      [this.fileType]: file,
    } as Record<FileType, File>;
    this.handleFileUpload.emit(fileObject);
  }

  private async isValidImageSize(file: File): Promise<boolean> {
    const { width, height } = await this.getImageSize(file);
    return (
      width >= +environment.minImgWidthPx &&
      height >= +environment.minImgHeightPx
    );
  }

  private getImageSize(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, _) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        return resolve({ width, height });
      };
    });
  }
}
