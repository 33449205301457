<app-header></app-header>
<ng-container *ngIf="loading; else app">
  <!-- TODO: replace with app loading component -->
  <div class="full-page-loading">
    <h1>Loading ....</h1>
  </div>
</ng-container>
<ng-template #app>
  <ng-container *ngIf="reservationError; else routerOutlet">
    <div class="reservation-not-found">
      <h1>{{ reservationError }}</h1>
    </div>
  </ng-container>
  <ng-template #routerOutlet>
    <router-outlet></router-outlet>
  </ng-template>
</ng-template>
