import { Environment } from './environment.types';

export const environment: Environment = {
  baseApiUrl: 'https://api.dev-rental.connexionmobility.com',
  dealerBaseApiUrl: 'https://dev-api.cxztrac.com',
  dealerApiKey: 'tEmD9RVvL9aWkzGtnBu287hoiQQMRgfL1K40OsQs',
  customerApiKey: 'IB85kPfvpL2EGfRPia2yJ9iacP53axoW5b5amzRR',
  vehicleApiKey: 'xO8m0xKxIo9eJ1znaOcTu8WjwRLUpV7U3wLLv7nX',
  paymentApiKey: 'ohGSOw2LjK2ePsRJoL5Hn1ZhWxtNPeAR2I4sC1Y2',
  minImgWidthPx: '800',
  minImgHeightPx: '400',
  licenseVerificationExpiryDays: '60',
};
